<template>
    <div>
        <div class="title">账号登录</div>

        <div class="input-box"><input placeholder="请输入您的手机号" type="text" v-model="mobile"/></div>
        <div class="input-box"><input placeholder="请输入登录密码" type="password" v-model="password"/></div>
        <div :class="'button' + (mobile && password ? ' active' : '')" @click="login">登1录</div>
        <div class="link-box">
            <div @click="navigateTo('/resetPassword')">找回密码</div>
            <div @click="navigateTo('/register')">用户注册</div>
        </div>
    </div>
</template>

<script>
    import {apiPost} from '@/utils/api.js'

    export default {

        data() {
            return {
                mobile: '',
                password: '',
            }
        },

        methods: {
            login() {
                if (this.mobile && this.password) {
                    apiPost('/auth/login', {mobile: this.mobile, password: this.password}).then(result => {
                        if (result.code === 0) {
                            this.$store.commit('login', result.data)
                            this.navigateTo('/');
                        }
                    })
                }
            },

            navigateTo(url) {
                this.$router.push(url)
            },
        }
    }
</script>

<style scoped>
    .title {
        margin-top: 2.70rem;
        margin-left: 0.50rem;
        line-height: 0.40rem;
        font-size: 0.40rem;
        font-family: Source Han Sans CN, Source Han Sans CN-Medium;
        font-weight: 500;
        color: #444444;
        margin-bottom: 0.80rem;
    }

    .input-box {
        padding: 0.45rem 0.20rem;
        width: 6.90rem;
        margin: 0 auto;
        border-bottom: 0.01rem solid #f6f6f6;
        box-sizing: border-box;
        font-size: 0.28rem;
    }

    .input-box input {
        width: 6.50rem;
        border: none;
        outline: none;
    }

    .button {
        margin: 1.00rem auto 0;
        width: 6.50rem;
        line-height: 0.84rem;
        background: #f9c3ac;
        border-radius: 0.10rem;

        font-size: 0.28rem;
        font-family: Source Han Sans CN, Source Han Sans CN-Medium;
        font-weight: 500;
        text-align: center;
        color: #ffffff;
    }

    .button.active {
        background: #FE7C45;
    }

    .link-box {
        display: flex;
        justify-content: space-around;
        margin-top: 0.40rem;
        padding: 0 0.60rem;
    }

    .link-box div {
        width: 1.50rem;
        font-size: 0.26rem;
        font-family: Source Han Sans CN, Source Han Sans CN-Regular;
        font-weight: 400;
        text-align: center;
        color: #0091ff;
        line-height: 0.35rem;
    }
</style>
